import Vue from 'vue'
import Vuex from 'vuex'
import search from './modules/search'
import materials from './modules/materials'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    search,
    materials
  }
})
