import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/styles.scss'

import { i18n } from './i18n'
import { Trans } from './plugins/translation'
import helpers from './helpers'
// import Cookie from './components/Cookie.vue';

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.prototype.Trans = Trans
Vue.prototype.helpers = helpers
// Vue.component('cookie-modal', Cookie);

Vue.config.productionTip = false

export const bus = new Vue();
bus.data = { result: [] }
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
