import { i18n } from '@/i18n'
export default {
  buildRoute(path) {
    const locale = i18n.locale
    if (locale !== 'ru') {
      return {
        ...path,
        name: path.name.includes('Loc') ? path.name : path.name + 'Loc',
        params: {
          ...path.params,
          locale: locale,
        },
      }
    } else {
      if (path.params && path.params.locale) {
        delete path.params.locale
      }
      return {
        ...path,
        name: path.name.replace('Loc', ''),
      }
    }
  },
  stripHtml(html) {
    let tmp = document.createElement('div')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  },
}
