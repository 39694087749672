<template>
  <div id="app">
    <component :is="layout"></component>
    <!-- <cookie-modal></cookie-modal> -->
  </div>
</template>

<script>
const defaultLayout = "default";
import Cookie from "./components/Cookie.vue";
import { bus } from "@/main";

export default {
  name: "App",
  components: {
    Cookie,
  },
  data: () => ({
    CookieState: false,
  }),
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout;
      return () => import(`@/layouts/${layout}.vue`);
    },
  },
  methods: {
    CookieAccept(state) {
      this.CookieState = state;
      if (!state) {
        const date = new Date();
        date.setDate(date.getDate() + 1500);
        localStorage.setItem("policy", "true");
      }
    },
  },
  watch: {
    $route(to, from) {
      bus.data.result = [];
      if (!localStorage.getItem("policy")) {
        this.CookieAccept(true);
      }
      setTimeout(() => {
        if (this.$route && this.$route.query && this.$route.query.t) {
          this.setTimecode(this.$route.query.t);
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.modal-popup {
  &__title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #830051;
    @media screen and (max-width: 767px) {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }

  .body-popup {
    &__text {
      margin-bottom: 16px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 22px;
      &.text-bold {
        font-weight: 500;
      }
      &.text-normal {
        font-weight: 400;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
