export default {
  state: {
    results: [],
    searchQuery: null
  },
  getters: {
    searchResults: (s) => s.results,
    searchQuery: (s) => s.searchQuery,
  },
  mutations: {
    updateSearchResults(s, { results, q }) {
      let reg = new RegExp(q, "gi");
      results.forEach(function (el) {
        el.origTitle = el.title || el.name;
        el.title =
          el.title?.replaceAll(reg, (str) => `<strong>${str}</strong>`) ||
          el.name?.replaceAll(reg, (str) => `<strong>${str}</strong>`);
      });
      s.results = results;
    },
    
    updateSearchQuery(s, query) {
      s.searchQuery = query;
    },
   
  },
  actions: {
    async fetchSearch(ctx, {q, currentLocale='ru'}) {
      await fetch(`https://astrazeneca.kz/api/search/?search=${q}&language=${currentLocale}`,{
        method: "GET",
      })
        .then(async (response) => {
          const result = await response.json()
          if(response.status === 200) {
            ctx.commit("updateSearchResults", { results: result, q: q });
            return true;
          } else {
            console.error(result);
            return false;
          }
         
        })
    },
    clearSearch(ctx) {
      ctx.commit("updateSearchResults", { results: [], q: "" });
    },
    setSearchQuery(ctx, q) {
      ctx.commit("updateSearchQuery", q);
    }
  },
};
