export default {
  state: {
    materials: [],
    detail: null,
  },
  getters: {
    allMaterials: (s) => s.materials,
    MaterialsDetail: (s) => s.detail,
  },
  mutations: {
    setMaterials(s, data) {
      s.materials = data;
    },
    setMaterialsDetail(s, data) {
      s.detail = data;
    },
  },
  actions: {
    async fetchMaterials({ commit }, {typeSlug, currentLocale='ru'}) {
      await fetch(
        `https://astrazeneca.kz/api/${typeSlug}/content/?language=${currentLocale}`,
        {
          method: "GET",
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
        //   console.log("Response data:", data);
          commit("setMaterials", data);
        })
        .catch((error) => {
          console.error("There was an error:", error);
        });
    },
    async fetchMaterialsDetail({ commit }, {typeSlug, MaterialId, currentLocale='ru'}) {
        await fetch(
          `https://astrazeneca.kz/api/${typeSlug}/content/${MaterialId}?language=${currentLocale}`,
          {
            method: "GET",
          }
        )
          .then((response) => {
            if (!response.ok) {
              if(response.status === 404) {
                commit("setMaterialsDetail", 404);
              }
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
          //   console.log("Response data:", data);
            commit("setMaterialsDetail", data);
          })
          .catch((error) => {
            console.error("There was an error:", error);
          });
      },
  },
};
