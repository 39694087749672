<template>
  <transition name="cookie-v">
    <div class="cookie">
      <div class="cookie-content content">
        <div class="cookie-content__descr">
          Мы применяем файлы cookie, чтобы сделать удобным использование вами
          нашего сайта. Продолжая использовать наш сайт, вы соглашаетесь
          на применение нами файлов cookie.
        </div>
        <div
          class="cookie-content__btn d-md-inline-flex button button_gray"
          @click="closeCookie"
        >
          Согласен
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Cookie",
  data: () => ({}),
  methods: {
    closeCookie() {
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-v-enter-active,
.cookie-v-leave-active {
  transition: all 0.5s ease;
}
.cookie-v-enter,
.cookie-v-leave-to {
  opacity: 0;
  transform: translateY(0);
}
.cookie {
  height: 194px;
  width: 100%;
  padding: 40px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #830051;
  z-index: 100000;
  &-content {
    display: flex;
    flex-direction: column;
    &__descr {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }
    &__btn {
      width: 116px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    height: 228px;
    padding: 24px 0;
  }
}
</style>
